export const sleep = async (ms: number) => {
    return await new Promise((resolve) => {
        setTimeout(resolve, ms);
    });
}


export const convertBrokenStringToFormattedMultilineString = (brokenString: string) => {
    // Remove <br> tags and replace them with a newline character
    const cleanedBio = brokenString?.replace(/<br>/g, '\n');

    // Split the biography into sentences based on the newline character
    const sentences = cleanedBio?.split('\n');

    // Filter out any empty strings that might result from the split
    return sentences
        .filter((sentence: string) => sentence.trim() !== '')
        ?.map((sentence: string) => {
            return sentence.trim() + '\n';
        })
}