import { pushEvent } from '@utils/analytics';
import { Button } from 'antd';
import { useEffect } from 'react';
import YouTube from 'react-youtube';

const NewLanderPage = () => {
    useEffect(() => {
        pushEvent('UserPageView', { pageName: 'GroupsListPage' });
    })

    return (
        <div style={{
            width: '100%',
            height: '100%',
            maxWidth: '100vw',
            overflowX: 'hidden',
            display: 'flex',
            gap: '2rem',
            padding: '2rem',
            margin: '2rem',
            textAlign: 'center',
            flexDirection: 'column',
            justifyContent: 'space-around',
            alignItems: 'center',
            background: '#000',
            color: '#fff'
        }}>
            <h3>🤫 We are still in beta access only. But check out our demo below and if you see some value, sign up for our waitlist! We'll give you early access😊</h3>
            <Button href={'https://forms.gle/cPe9x2RA5DFPKviv9'} >
                Sign up for waitlist
            </Button>
            <YouTube
                videoId={'P7W2sonLBko'}                  // defaults -> ''
            // id={string}                       // defaults -> ''
            // className={string}                // defaults -> ''
            // iframeClassName={string}          // defaults -> ''
            // style={object}                    // defaults -> {}
            // title={string}                    // defaults -> ''
            // loading={string}                  // defaults -> undefined
            // opts={obj}                        // defaults -> {}
            // onReady={func}                    // defaults -> noop
            // onPlay={func}                     // defaults -> noop
            // onPause={func}                    // defaults -> noop
            // onEnd={func}                      // defaults -> noop
            // onError={func}                    // defaults -> noop
            // onStateChange={func}              // defaults -> noop
            // onPlaybackRateChange={func}       // defaults -> noop
            // onPlaybackQualityChange={func}    // defaults -> noop
            />

        </div>
    )
}

export default NewLanderPage
