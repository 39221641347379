import { Contact } from "@models/contact.model";
import { RecordType } from "@models/index";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MenuProps } from "antd";

export enum ActiveModalType {
    CONTACT_MODAL = "CONTACT_MODAL",
    ONLINE_SEARCH_MODAL = "ONLINE_SEARCH_MODAL",
    NOTE_MODAL = "NOTE_MODAL",
    ACTION_MODAL = "ACTION_MODAL",
    EDIT_SUGGESTED_ACTIONS = "EDIT_SUGGESTED_ACTIONS",
}

export enum BottomSheetType {
    BIZ_CARD_ADD = "BIZ_CARD_ADD",
    SEARCH_ADD = "SEARCH_ADD",
    CONTACT_ADD = "CONTACT_ADD",
    QR_SCAN = "QR_SCAN",
    GROUP_EDIT = "GROUP_EDIT",
    GROUP_ADD = "GROUP_ADD",
    PLAYBOOK_ADD = "PLAYBOOK_ADD",
    ENHANCE_LINKEDIN = "ENHANCE_LINKEDIN",
    NOTE_ADD = "NOTE_ADD"
}

export enum ActiveRouteKey {
    SEARCH = "search",
    SEARCH2 = "chat",
    ACTIONS = "actions",
    CONTACTS = "contacts",
    GROUPS = "groups",
    ACCOUNT = "account",
    HOME = "home",
    PLAYBOOKS = "playbooks",
    UPLOADS = "uploads"
}

export enum SearchType {
    INTERNAL = "INTERNAL",
    EXTERNAL = "EXTERNAL"
}

interface ActiveEntitiesState {
    isModalOpen: boolean;
    modalType: ActiveModalType;
    activeQueryString: string;
    geolocation: string;
    isBottomSheetOpen: boolean;
    bottomSheetType?: BottomSheetType;
    appBarActiveKey: ActiveRouteKey;
    searchType: SearchType;
    activePlaybookId: string;
    activeSearchResponseRecordTypes: RecordType[];
    showSpotlightSearch: boolean;
    handleBottomSheetClose?: (value: any) => any | null;
    handleModalClose?: (value: any) => any | null;
    activeMenuPropItemsGetterFunc: (c: Contact) => MenuProps["items"];
    isSentryInitialized: boolean;
    searchAttemptId: string;
    activeChatCycleId: string;
    audioNoteContent: string;
    activeVideoUrl: string;
    rightDrawerOpen: boolean;
}

const initialState: ActiveEntitiesState = {
    isModalOpen: false,
    isBottomSheetOpen: false,
    modalType: ActiveModalType.CONTACT_MODAL,
    activeQueryString: "",
    geolocation: "",
    appBarActiveKey: ActiveRouteKey.HOME,
    searchType: SearchType.EXTERNAL,
    handleBottomSheetClose: () => { },
    handleModalClose: () => { },
    activeSearchResponseRecordTypes: [],
    activeMenuPropItemsGetterFunc: () => [],
    isSentryInitialized: false,
    searchAttemptId: "",
    activeChatCycleId: "",
    showSpotlightSearch: false,
    audioNoteContent: "",
    activePlaybookId: "",
    activeVideoUrl: "",
    rightDrawerOpen: false
}

export const activeEntitiesSlice = createSlice({
    name: "activeEntities",
    initialState,
    reducers: {
        setIsModalOpen(state: ActiveEntitiesState, action: PayloadAction<boolean>) {
            state.isModalOpen = action.payload
        },
        setIsBottomSheetOpen(state: ActiveEntitiesState, action: PayloadAction<boolean>) {
            state.isBottomSheetOpen = action.payload
        },
        setActiveQueryString(state: ActiveEntitiesState, action: PayloadAction<string>) {
            state.activeQueryString = action.payload
        },
        setModalType(state: ActiveEntitiesState, action: PayloadAction<ActiveModalType>) {
            state.modalType = action.payload
        },
        setBottomSheetType(state: ActiveEntitiesState, action: PayloadAction<BottomSheetType>) {
            state.bottomSheetType = action.payload
        },
        setGeoLocation(state: ActiveEntitiesState, action: PayloadAction<string>) {
            state.geolocation = action.payload
        },
        setActiveRouteKey(state: ActiveEntitiesState, action: PayloadAction<ActiveRouteKey>) {
            state.appBarActiveKey = action.payload
        },
        setSearchType(state: ActiveEntitiesState, action: PayloadAction<SearchType>) {
            state.searchType = action.payload
        },
        setHandleBottomSheetClose(state: ActiveEntitiesState, action: PayloadAction<(value: any) => any>) {
            state.handleBottomSheetClose = action.payload
        },
        setHandleModalClose(state: ActiveEntitiesState, action: PayloadAction<(value: any) => any>) {
            state.handleModalClose = action.payload
        },
        setActiveSearchResponseRecordTypes(state: ActiveEntitiesState, action: PayloadAction<RecordType[]>) {
            state.activeSearchResponseRecordTypes = action.payload
        },
        setActiveMenuPropItemsGetterFunc(state: ActiveEntitiesState, action: PayloadAction<(c: Contact) => MenuProps["items"]>) {
            state.activeMenuPropItemsGetterFunc = action.payload
        },
        setIsSentryInitialized(state: ActiveEntitiesState, action: PayloadAction<boolean>) {
            state.isSentryInitialized = action.payload
        },
        setSearchAttemptId(state: ActiveEntitiesState, action: PayloadAction<string>) {
            state.searchAttemptId = action.payload
        },
        setActiveChatCycleId(state: ActiveEntitiesState, action: PayloadAction<string>) {
            state.activeChatCycleId = action.payload
        },
        setShowSpotlightSearch(state: ActiveEntitiesState, action: PayloadAction<boolean>) {
            state.showSpotlightSearch = action.payload
        },
        setAudioNoteContent(state: ActiveEntitiesState, action: PayloadAction<string>) {
            state.audioNoteContent = action.payload
        },
        setActivePlaybookId(state: ActiveEntitiesState, action: PayloadAction<string>) {
            state.activePlaybookId = action.payload
        },
        setActiveVideoUrl(state: ActiveEntitiesState, action: PayloadAction<string>) {
            state.activeVideoUrl = action.payload
        },
        setRightDrawerOpen(state: ActiveEntitiesState, action: PayloadAction<boolean>) {
            state.rightDrawerOpen = action.payload
        }
    },
});

export const {
    setIsModalOpen,
    setIsBottomSheetOpen,
    setActiveQueryString,
    setModalType,
    setBottomSheetType,
    setGeoLocation,
    setActiveRouteKey,
    setSearchType,
    setHandleBottomSheetClose,
    setHandleModalClose,
    setActiveSearchResponseRecordTypes,
    setActiveMenuPropItemsGetterFunc,
    setIsSentryInitialized,
    setSearchAttemptId,
    setActiveChatCycleId,
    setShowSpotlightSearch,
    setAudioNoteContent,
    setActivePlaybookId,
    setActiveVideoUrl,
    setRightDrawerOpen
} = activeEntitiesSlice.actions;

export default activeEntitiesSlice.reducer;