import ContactCard from '@components/atoms/ContactCard';
import { Contact } from '@models/contact.model';
import { AppDispatch, RootState } from '@redux/store';
import { pushEvent } from '@utils/analytics';
import apiService from '@utils/api/api-service';
import { Button, Collapse, List, Spin } from 'antd';
import React from 'react'
import { useQuery } from 'react-query';
import './AIChatContactsList.css'
import { useDispatch, useSelector } from 'react-redux';
import { PlusCircleOutlined } from '@ant-design/icons';
import toast from 'react-hot-toast';
import { CompleteNote } from '@models/note.model';
import { CompleteAction } from '@models/action.model';
import { CompleteRelationship } from '@models/relationship.model';
import { RecordType } from '@models/index';
import NoteCard from '@components/atoms/NoteCard';
import ActionCard from '@components/atoms/ActionCard';
import RelationshipCard from '@components/atoms/RelationshipCard';
import { setActiveContacts } from '@redux/features/contactSlice';
import { BottomSheetType, setActiveQueryString, setBottomSheetType, setIsBottomSheetOpen } from '@redux/features/activeEntitiesSlice';
import { initialGroupState, setGroup } from '@redux/features/groupSlice';
import { LinkedinContact } from '@models/linkedinContact.model';
import LinkedinContactCard from '@components/atoms/LinkedinContactCard';
import HistoryCard from '@components/atoms/HistoryCard';
import { CompleteHistory } from '@models/history.model';
import SetAISearchCategories from '../SetAISearchCategories';
import { ChatCycle, ChatResponse, updateChatThreadWithResponse } from '@redux/features/chatSlice';

interface AIChatContactsListProps {
    requestQuery: string;
    chatCycleId: string;
}

export type PossibleSearchResponseType = (CompleteNote | CompleteAction | CompleteRelationship | Contact | LinkedinContact | CompleteHistory) & { score: number };

interface SearchResponseInterface {
    notes?: CompleteNote[];
    contacts?: Contact[];
    actions?: CompleteAction[];
    linkedinContacts?: LinkedinContact[];
    relationships?: CompleteRelationship[];
    orderedResultsByScore?: PossibleSearchResponseType[];
    searchAttemptId: string;

}

export const renderItemOnChatResponseRecordType = ({ item, chatCycleId, index }: { item: PossibleSearchResponseType, chatCycleId: string, index: number }) => {
    switch (item.recordType) {
        case RecordType.CONTACT:
            return (
                <List.Item key={index}>
                    <ContactCard chatCycleId={chatCycleId} contact={item as Contact} />
                </List.Item>
            )
        case RecordType.NOTE:
            return (
                <List.Item key={index}>
                    <NoteCard chatCycleId={chatCycleId} note={item as CompleteNote} />
                </List.Item>
            )
        case RecordType.ACTION:
            return (
                <List.Item key={index}>
                    <ActionCard chatCycleId={chatCycleId} action={item as CompleteAction} />
                </List.Item>
            )
        case RecordType.RELATIONSHIP:
            return (
                <List.Item key={index}>
                    <RelationshipCard chatCycleId={chatCycleId} relationship={item as CompleteRelationship} />
                </List.Item>
            )
        case RecordType.LINKEDIN_CONTACT:
            return (
                <List.Item key={index}>
                    <LinkedinContactCard chatCycleId={chatCycleId} liContact={item as LinkedinContact} />
                </List.Item>
            )
        case RecordType.HISTORY:
            return (
                <List.Item key={index}>
                    <HistoryCard chatCycleId={chatCycleId} history={item as CompleteHistory} />
                </List.Item>
            )
        default:
            pushEvent('UnresolvedAISearchResultType', { item })
        // return <p>Unresolved {item.recordType}</p>
    }
}

const AIChatContactsList: React.FC<AIChatContactsListProps> = ({ requestQuery, chatCycleId }) => {
    const user = useSelector((state: RootState) => state.persisted.user.value);
    const activeSearchResponseRecordTypes = useSelector((state: RootState) => state.activeEntities.activeSearchResponseRecordTypes);
    const searchAttemptId = useSelector((state: RootState) => state.activeEntities.searchAttemptId);
    const activeQueryString = useSelector((state: RootState) => state.activeEntities.activeQueryString);
    const activeChatCycleId = useSelector((state: RootState) => state.activeEntities.activeChatCycleId);
    const chatCycles = useSelector((state: RootState) => state.chat.value.chatCycles);
    // Get chatCycle by chatCycleId
    const chatCycle = chatCycles.find((chatCycle: ChatCycle) => chatCycle.id === chatCycleId);

    const dispatch: AppDispatch = useDispatch();
    const { isLoading } = useQuery({
        queryKey: ["getRecommendedContacts"],
        queryFn: async () => {
            if (!requestQuery) return null;
            const startTime = new Date().getTime();
            const res: SearchResponseInterface = await apiService.searchInUserNetwork(
                requestQuery,
                user.email,
                user.token,
            )
            const endTime = new Date().getTime();
            pushEvent('AISearchContactsReturned', {
                requestQuery,
                responseTime: (endTime - startTime) / 1000,
            })
            dispatch(setActiveQueryString(requestQuery));

            const chatResponse: ChatResponse = {
                text: "",
                timestamp: new Date().toISOString(),
                sender: 'bot',
                data: {
                    searchResults: res.orderedResultsByScore
                }
            }
            dispatch(updateChatThreadWithResponse({
                chatResponse,
                chatCycleId: activeChatCycleId
            }));
        },
        enabled: requestQuery.length > 0 && activeQueryString !== chatCycle?.request!.text
    })

    const orderedResultsByScore = chatCycle?.response?.data?.searchResults as PossibleSearchResponseType[];
    const contacts = orderedResultsByScore?.filter((item: PossibleSearchResponseType) => item.recordType === RecordType.CONTACT) as Contact[];

    const filteredOrderedResultsByScore = activeSearchResponseRecordTypes?.length > 0 ? orderedResultsByScore
        // TODO - Patchfix - move this to the backend so you save latency in data transfer
        // ?.filter((item: any) => item.score > 0.84)
        ?.filter((item: PossibleSearchResponseType) => activeSearchResponseRecordTypes.includes(item.recordType)) : orderedResultsByScore;

    return (
        isLoading ? <Spin /> : <div className='chatRecommendedContactsContainer'>
            {orderedResultsByScore?.length > 0 &&
                <>
                    <div style={{
                        background: '#fff',
                        padding: '0.5rem',
                        borderRadius: '0.5rem',
                    }}>
                        <Collapse
                            style={{ background: 'white' }}
                            items={[{
                                key: '1',
                                label: 'Relevant Sources',
                                children: <List
                                    itemLayout="horizontal"
                                    dataSource={filteredOrderedResultsByScore}
                                    renderItem={(item: PossibleSearchResponseType, index) =>
                                        renderItemOnChatResponseRecordType({ item, chatCycleId, index })
                                    }

                                />

                            }]}
                        />
                    </div>
                    {/* Add 2 thumbs up and down buttons using the icons in AntDesign */}
                    {searchAttemptId?.length > 0 &&
                        <div className="thumbsUpAndDown">
                            <Button
                                onClick={async () => {
                                    const searchAttempt = {
                                        user,
                                        isLiked: chatCycle?.response?.isLiked,
                                        query: chatCycle?.request?.text!,
                                        searchResults: chatCycle?.response?.data?.searchResults || [],
                                        answer: chatCycle?.response?.text!
                                    }
                                    await apiService.updateSearchAttempt(chatCycle?.id!, searchAttempt)
                                    toast.success('Thanks for the feedback!')
                                    pushEvent('SubmitAIChatFeedback', { query: activeQueryString, orderedResultsByScore })
                                }}
                            >Submit Feedback</Button>&nbsp; &nbsp;
                            <Button
                                icon={<PlusCircleOutlined />}
                                onClick={() => {
                                    dispatch(setActiveContacts(contacts));
                                    dispatch(setActiveQueryString(''))
                                    dispatch(setIsBottomSheetOpen(true))
                                    dispatch(setBottomSheetType(BottomSheetType.GROUP_ADD))
                                    dispatch(setGroup(initialGroupState.activeGroup))
                                }}
                            >
                                Create List
                            </Button>
                        </div>}
                    <SetAISearchCategories />
                </>
            }
        </div>
    )
}

export default AIChatContactsList
