import { CameraFilled, ForwardOutlined, QrcodeOutlined, SearchOutlined } from '@ant-design/icons';
import useDebounce from '@hooks/useDebounce';
import { RecordType } from '@models/index';
import { CompleteContact, ContactOnlineSearchResult, Contact } from '@models/contact.model';
import { BottomSheetType, SearchType, setActiveQueryString, setAudioNoteContent, setBottomSheetType, setIsBottomSheetOpen, setSearchType } from '@redux/features/activeEntitiesSlice';
import { setContact } from '@redux/features/contactSlice';
import { AppDispatch, RootState } from '@redux/store';
import { pushEvent } from '@utils/analytics';
import { extractNameAndCompanyNameFromNote } from '@utils/contactFuncs';
import { performGoogleSearch } from '@utils/performGoogleSearch';
import { Button, Input, Spin } from 'antd';
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import ScanCardV2 from '../ScanCardV2';
import apiService from '@utils/api/api-service';
import OnlineSearchItemsList from '../OnlineSearchItemsList';
import InternalSearchItemsList from '../InternalSearchItemsList';
import './SearchAndResults.css'
import OnlineSearchCategories from '../OnlineSearchCategories';

const SearchAndResults = () => {
    const [results, setResults] = useState<ContactOnlineSearchResult[] | Contact[]>([]);
    const { activeQueryString, searchType: sType, handleBottomSheetClose } = useSelector((state: RootState) => state.activeEntities);
    const { email, token } = useSelector((state: RootState) => state.persisted.user.value);
    const contact = useSelector((state: RootState) => state.contact.value.activeContact);
    const [query, setQuery] = useState<string>(activeQueryString);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const inputRef = useRef(null);
    const [searchType, setCurrentSearchType] = useState<SearchType>(sType); // Default to internal search type
    const dispatch: AppDispatch = useDispatch();
    const audioNoteContent = useSelector((state: RootState) => state.activeEntities.audioNoteContent);


    useEffect(() => {
        // if query is already set, fire handleSearch, right when the component is loading up
        if (inputRef.current) {
            (inputRef.current as any)?.focus();
        }
        if (query) {
            setQuery(query);
            handleSearch();
        }
        // eslint-disable-next-line
    }, [activeQueryString, searchType])

    useDebounce(() => {
        handleSearch();
        if (inputRef.current) {
            (inputRef.current as any).focus();
        }
        // eslint-disable-next-line
    }, 10, [query])

    const handleNext = async () => {
        if (searchType === SearchType.INTERNAL ? true : contact.notes) {
            setIsLoading(true);
            if (searchType === SearchType.INTERNAL) {
                // Because in the flow of the internal search type, before clicking next, we will have already selected a finite contact that exists and have that set as the activeContact in the contactSlice in Redux. So we can just kill the flow here after passing an update function.
                dispatch(setContact(contact))
                dispatch(setIsBottomSheetOpen(false))
                dispatch(setSearchType(SearchType.EXTERNAL));
                handleBottomSheetClose!({ contact, activeQueryString: audioNoteContent });
                pushEvent('SetContactAndQueryForAddingNote', { contact, activeQueryString })
            } else {
                // In the SearchType EXTERNAL, i.e. the flow where we attach links, the person will always have a note that we construct from the attached link.
                const firstNote = contact.notes![0];
                const extractedName = await extractNameAndCompanyNameFromNote(firstNote.content);
                const name = extractedName?.length > 0 ? extractedName : activeQueryString;
                const newContact: CompleteContact = {
                    name,
                    biography: firstNote.content,
                    namespace: email,
                    location: firstNote.location,
                    noteIds: [firstNote.id!],
                    notes: [firstNote],
                    recordType: RecordType.CONTACT,
                    timestamp: firstNote.timestamp,

                    // Empty fields
                    email: "",
                    phone: "",
                    interests: [],
                    occupation: "",
                    organization_name: "",
                    relationshipId: ""

                }
                dispatch(setContact(newContact))
                dispatch(setActiveQueryString(''))
            }
            dispatch(setAudioNoteContent(audioNoteContent))
            dispatch(setBottomSheetType(BottomSheetType.CONTACT_ADD))
            pushEvent('UserSearchPersonOnlineNext', { query })
            setIsLoading(false);
        }
    }

    const handleSearchTypeSwitch = () => {
        const newSearchType = searchType === SearchType.EXTERNAL ? SearchType.INTERNAL : SearchType.EXTERNAL;
        pushEvent('SwitchSearchType', { newSearchType })
        setCurrentSearchType(newSearchType);
    }

    const handleSearch = async () => {
        if (!query) {
            setResults([]);
            setIsLoading(false);
            return;
        };
        const startTime = new Date().getTime();
        setIsLoading(true);
        if (searchType === SearchType.INTERNAL) {
            pushEvent('SearchPersonInNetwork', { query })
            const response = await apiService.searchContact(query, email, token);
            setResults(response as Contact[]);
        } else {
            pushEvent('SearchPersonOnline', { query })
            const response = await performGoogleSearch(query, email);
            setResults(response as ContactOnlineSearchResult[]);
        }
        setIsLoading(false);
        const endTime = new Date().getTime();
        pushEvent('UserSearchPersonCompleted', { query, responseTime: (endTime - startTime) / 1000, searchType })
    };

    return (
        <>
            <div
                style={{ display: 'flex', flexDirection: 'column' }}
            >
                <Input
                    ref={inputRef}
                    placeholder="Search for new contact"
                    value={query}
                    defaultValue={activeQueryString}
                    onChange={(e) => setQuery(e.target.value)}
                    suffix={
                        <SearchOutlined
                            onClick={() => handleSearch()}
                            style={{ cursor: 'pointer' }}
                        />
                    }
                />
                <br />

                <Button
                    style={{ alignSelf: 'flex-end' }}
                    loading={isLoading}
                    icon={<ForwardOutlined />} key="search" type="default" onClick={() => handleSearchTypeSwitch()}
                >
                    Switch to {searchType === SearchType.EXTERNAL ? 'My Network' : 'Online'} Search
                </Button>
            </div>
            &nbsp;&nbsp;
            {isLoading ? <Spin /> :
                <div style={{ marginBottom: '10rem' }}> {results?.length === 0 &&
                    <p>Search keywords of the person you want to look up</p>}
                    {results?.length > 0
                        ?
                        isLoading
                            ? <Spin />
                            : searchType === SearchType.EXTERNAL
                                ? <OnlineSearchItemsList
                                    results={results as ContactOnlineSearchResult[]}
                                />
                                : <InternalSearchItemsList
                                    results={results as Contact[]}
                                />
                        : <p> </p>
                    }
                </div>}
            <div className="bottomBar">
                {/* Simple div that is a gradient from clear to pure white and has height of 5rem to show the user that the list is scrollable */}
                <div style={{
                    width: '100%',
                    height: '10rem',
                    background: 'linear-gradient(180deg, transparent 0%, #fff 80%)',
                }} />
                <div className="bottomActionsWrapper">
                    {query.length > 0 &&
                        <OnlineSearchCategories
                            query={query}
                            setQuery={setQuery}
                        />
                    }
                    <div className='buttonContainer'>
                        <ScanCardV2 />
                    </div>
                    <div className='buttonContainer'>
                        <Button
                            disabled={isLoading}
                            icon={<QrcodeOutlined />}
                            onClick={() => {
                                dispatch(setIsBottomSheetOpen(true));
                                dispatch(setBottomSheetType(BottomSheetType.QR_SCAN));
                            }}
                        >
                            Scan QR
                        </Button>
                    </div>
                    <div className="bottomActionContainer" >
                        <Button
                            loading={isLoading}
                            disabled={searchType === SearchType.INTERNAL ? false : !contact.notes?.length}
                            icon={<ForwardOutlined />} key="search" type="primary" onClick={handleNext}
                        >
                            Next
                        </Button>
                        <br />
                    </div>
                </div>
            </div>
        </>
    )
}

export default SearchAndResults
