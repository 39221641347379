import { AppDispatch, RootState } from '@redux/store';
import apiService from '@utils/api/api-service';
import { Button, FloatButton, Spin, Tag } from 'antd';
import { useEffect } from 'react'
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ActiveRouteKey, BottomSheetType, setActivePlaybookId, setActiveQueryString, setActiveRouteKey, setBottomSheetType, setIsBottomSheetOpen } from '@redux/features/activeEntitiesSlice';
import { EditOutlined } from '@ant-design/icons';
import { pushEvent } from '@utils/analytics';
import './PlaybookPage.css'
import { initialPlaybookState, setActivePlaybook } from '@redux/features/playbookSlice';
import { CompleteTemplate } from '@models/template.model';
import { ContentBlock } from '@models/contentBlock.model';
import toast from 'react-hot-toast';

const PlaybookPage = () => {
    const location = useLocation();
    const templateId = location.pathname.split("/")[2];
    const { email, token } = useSelector((state: RootState) => state.persisted.user.value)
    const dispatch: AppDispatch = useDispatch();
    const { isLoading } = useQuery({
        queryKey: ["getCompletePlaybook", templateId, email, token],
        queryFn: async ({ queryKey }) => {
            if (!queryKey[1]) return null;
            const response = await apiService.getFullTemplate(queryKey[1], queryKey[2]!, queryKey[3]!)
            console.log(response);
            dispatch(setActivePlaybook(response as CompleteTemplate));
        },
    })
    const playbook = useSelector((state: RootState) => state.playbook.activePlaybook);

    useEffect(() => {
        pushEvent('UserPageView', { pageName: 'IndividualPlaybookPage' });
        dispatch(setActiveRouteKey(ActiveRouteKey.PLAYBOOKS))
        return () => {
            setActivePlaybook(initialPlaybookState.activePlaybook)
        }
    }, [dispatch]);

    const handleEditClick = () => {
        dispatch(setActiveQueryString(''))
        dispatch(setIsBottomSheetOpen(true))
        dispatch(setActivePlaybook(playbook))
        dispatch(setBottomSheetType(BottomSheetType.PLAYBOOK_ADD))
    }

    return (
        isLoading
            ? <Spin />
            : <div className='playbookPage'>
                <h1 style={{ marginBottom: 0 }}>{playbook.name}</h1>
                <Button type="primary" style={{ marginBottom: '1rem' }} onClick={() => {
                    dispatch(setActivePlaybookId(playbook._id!))
                    toast.success('Playbook set as active')
                }}>Set Active</Button>
                <h4 style={{ fontStyle: 'italic' }}>{playbook.description}</h4>
                {playbook.contentBlocks?.map((block: ContentBlock, index) => {
                    return (
                        <div key={index} className='playbookBlock'>
                            <h3>{block.blockTitle}</h3>
                            <p>{block.description}</p>
                            {block?.optionIds && block?.optionIds.length > 0 &&
                                <>
                                    <span>Options: </span>{block.optionIds?.map((option, index) => (
                                        <Tag color="blue" style={{ marginRight: '0.5rem' }} key={index}>{option.optionText}</Tag>
                                    ))}
                                </>
                            }
                            <br /><br /><br /><br /><br />
                        </div>
                    )
                })}
                <FloatButton
                    type="primary"
                    style={{ bottom: 100 }}
                    icon={<EditOutlined />}
                    onClick={handleEditClick}
                />
            </div>
    )
}

export default PlaybookPage;
