import { ContentBlock } from "@models/contentBlock.model";
import { callApi } from "@utils/api/api-service";

export const getContentBlock = async (contentBlockId: string, accessToken: string) => {
    return await callApi({
        method: "GET",
        url: `/contentBlocks/${contentBlockId}`,
        accessToken,
        serializerFunc: (r: { data: ContentBlock }) => r.data
    });
};

export const createContentBlock = async (contentBlock: Partial<ContentBlock>, accessToken: string) => {
    return await callApi({
        method: "POST",
        url: `/contentBlocks`,
        accessToken,
        requestBody: contentBlock,
        serializerFunc: (r: { data: ContentBlock }) => r.data
    });
};

export const updateContentBlock = async (contentBlockId: string, contentBlock: Partial<ContentBlock>, accessToken: string) => {
    return await callApi({
        method: "PUT",
        url: `/contentBlocks/${contentBlockId}`,
        accessToken,
        requestBody: contentBlock,
        serializerFunc: (r: { data: ContentBlock }) => r.data
    });
};

export const deleteContentBlock = async (contentBlockId: string, accessToken: string) => {
    return await callApi({
        method: "DELETE",
        url: `/contentBlocks/${contentBlockId}`,
        accessToken,
        serializerFunc: () => { }
    });
};
