import { Document } from "@models/document.model";
import { callApi } from "@utils/api/api-service";

export const getDocument = async (documentId: string, accessToken: string) => {
    return await callApi({
        method: "GET",
        url: `/documents/${documentId}`,
        accessToken,
        serializerFunc: (r: { data: Document }) => r.data
    });
};

export const createDocument = async (document: Partial<Document>, accessToken: string) => {
    return await callApi({
        method: "POST",
        url: `/documents`,
        accessToken,
        requestBody: document,
        serializerFunc: (r: { data: Document }) => r.data
    });
};

export const updateDocument = async (documentId: string, document: Partial<Document>, accessToken: string) => {
    return await callApi({
        method: "PUT",
        url: `/documents/${documentId}`,
        accessToken,
        requestBody: document,
        serializerFunc: (r: { data: Document }) => r.data
    });
};

export const deleteDocument = async (documentId: string, accessToken: string) => {
    return await callApi({
        method: "DELETE",
        url: `/documents/${documentId}`,
        accessToken,
        serializerFunc: () => { }
    });
};
