import { SendOutlined } from '@ant-design/icons';
import { pushEvent } from '@utils/analytics';
import { uploadAudioForTranscription } from '@utils/transcribeAudio';
import { Button, Form, Input } from 'antd';
import React, { useState } from 'react'
import './ChatBar.css'
import { RecordType } from '@models/index';
import AudioRecorder from '../AudioRecorder';
import toast from 'react-hot-toast';

interface IChatBar {
    actionEntityType: RecordType;
    action: string;
    handleUpload: (message: string) => void
}

const ChatBar: React.FC<IChatBar> = ({ handleUpload, action, actionEntityType }) => {
    // const recorderControls = useAudioRecorder()
    const [loading, setLoading] = useState<boolean>(false);
    const [noteMessage, setNoteMessage] = useState<string>("");

    const handleAudioUpload = async (file: any) => {
        setLoading(true);
        const startTime = new Date().getTime();
        const data: { text: string } = await uploadAudioForTranscription(file)
        const query: string = data.text;
        await handleUpload(query);
        setLoading(false);
        const endTime = new Date().getTime();
        const duration = (endTime - startTime) / 1000; // duration in seconds
        pushEvent(action + "ByVoice", {
            // recordingDuration: recorderControls.recordingTime,
            responseTime: duration
        })
        setNoteMessage("");
        // dispatch(setActiveQueryString(""));
    }

    const returnPlaceholderBasedOnActionType = () => {
        switch (actionEntityType) {
            case RecordType.NOTE:
                return "Add Note";
            case RecordType.ACTION:
                return "Add Action";
            case RecordType.RELATIONSHIP:
                return "Add Relationship Context";
            case RecordType.CONTACT:
                return "Search";
            default:
                return "Add Note";
        }
    }
    const [form] = Form.useForm();

    const handleFinish = async () => {
        pushEvent(action, { message: noteMessage })
        await handleUpload(noteMessage);
        setNoteMessage("");
    }

    return (
        <div className={`chatContainer`}>
            <Form form={form} style={{ width: '100%' }} onFinish={handleFinish}>
                <Form.Item >
                    <Input
                        placeholder={returnPlaceholderBasedOnActionType()}
                        value={noteMessage}
                        onChange={(e) => {
                            setNoteMessage(e.target.value);
                        }}
                    />
                </Form.Item>
            </Form>
            <Button
                icon={<SendOutlined />}
                type="primary"
                style={{ height: '100%' }}
                loading={loading}
                onClick={async () => {
                    if (noteMessage.length === 0) {
                        return toast('Kindly enter something before submitting!')
                    }
                    pushEvent(action, { message: noteMessage })
                    await handleUpload(noteMessage);
                    setNoteMessage("");
                }
                }
            />
            <AudioRecorder handleAudioFile={handleAudioUpload} />
            {/* <AudioRecorder
                onRecordingComplete={async (blob: any) => {
                    const file = await addAudioElement(blob)
                    const toastId = toast.loading('Searching...');
                    try {
                        await handleAudioUpload(file);
                        toast.success('Here is your contact', { id: toastId });
                    } catch (error: any) {
                        toast.error(`Could not search - ${error.message}`, { id: toastId });
                    }

                }
                }
                audioTrackConstraints={{
                    noiseSuppression: true,
                    echoCancellation: true,
                }}
                onNotAllowedOrFound={async (blob: any) => {
                    toast('Recording not allowed or found on this device, please email founders@recontact.world for more details');
                }}
                showVisualizer={true}
                downloadOnSavePress={false}
                downloadFileExtension="webm"
            /> */}
        </div>
    )
}

export default ChatBar
