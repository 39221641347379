import { RecordType } from ".";
import { CompleteLinkedinContact } from "./linkedinContact.model";

export interface User {
    id?: string;
    email: string
    name?: string;
    photoURL?: string;


    biography?: string;
    interests?: string[];
    occupation?: string;
    organization_name?: string;
    location?: string;
    phone?: string;

    token: string
    calendarId: string
    subscriptionExpiryDate?: string
    status?: UserStatus;
    recordType?: RecordType;

    linkedinContactId?: string;


    // For connecting to CRMs
    ownerId?: string
}


export interface CompleteUser extends User {
    linkedinContact?: CompleteLinkedinContact
}

export enum UserStatus {
    UNAUTHENTICATED = "UNAUTHENTICATED",
    UNSUBSCRIBED = "UNSUBSCRIBED",
    SUBSCRIBED = "SUBSCRIBED"
}