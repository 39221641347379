import { callApi } from "@utils/api/api-service";
import { Video } from "@models/video.model";

export const getVideoTranscriptFromText = async (videoUrl: string, accessToken: string) => {
    return await callApi({
        method: "POST",
        url: `/videos/transcribeVideo`,
        accessToken,
        requestBody: { videoUrl },
        serializerFunc: (r: { data: any }) => r.data // Adapt the return type as needed
    });
}

export const getVideo = async (id: string, accessToken: string) => {
    return await callApi({
        method: "GET",
        url: `/videos/${id}`,
        accessToken,
        serializerFunc: (r: { data: any }) => r.data // Adapt the return type as needed
    });
};

export const getAllUserVideos = async (userId: string, accessToken: string) => {
    return await callApi({
        method: "GET",
        url: `/videos/all/${userId}`,
        accessToken,
        serializerFunc: (r: { data: Video[] }) => r.data
    });
};

export const createVideo = async (videoData: Partial<Video>, accessToken: string) => {
    return await callApi({
        method: "POST",
        url: "/videos/",
        accessToken,
        requestBody: videoData,
        serializerFunc: (r: { data: any }) => r.data // Adapt the return type as needed
    });
};

export const updateVideo = async (id: string, videoData: Partial<Video>, accessToken: string) => {
    return await callApi({
        method: "PUT",
        url: `/videos/${id}`,
        accessToken,
        requestBody: videoData,
        serializerFunc: (r: { data: any }) => r.data // Adapt the return type as needed
    });
};

export const deleteVideo = async (id: string, accessToken: string) => {
    return await callApi({
        method: "DELETE",
        url: `/videos/${id}`,
        accessToken,
        serializerFunc: () => { } // No response data expected for delete operation
    });
};
