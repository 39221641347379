import { BookOutlined, CheckCircleOutlined, DeleteOutlined, MoreOutlined } from '@ant-design/icons';
import { ActiveRouteKey, BottomSheetType, setActivePlaybookId, setActiveQueryString, setActiveRouteKey, setBottomSheetType, setIsBottomSheetOpen } from '@redux/features/activeEntitiesSlice';
import { AppDispatch, RootState } from '@redux/store';
import { pushEvent } from '@utils/analytics';
import apiService from '@utils/api/api-service';
import { AutoComplete, Dropdown, FloatButton, Spin, Tag } from 'antd';
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import './VideosListPage.css'
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { Video } from '@models/video.model';
import { setVideosList } from '@redux/features/video';

const VideosListPage = () => {
    const { email, token } = useSelector((state: RootState) => state.persisted.user.value);
    const videosList = useSelector((state: RootState) => state.video.videosList);
    const [searchQuery, setSearchQuery] = useState('');
    const { isLoading } = useQuery({
        queryKey: ["getAllUserVideos", email, token],
        queryFn: async ({ queryKey }) => {
            if (!queryKey[1]) return null;
            const videosReturned = await apiService.getAllUserVideos(queryKey[1], queryKey[2]!);
            const videos = [...videosReturned]?.sort((a: Video, b: Video) => a.name.localeCompare(b.name)) as Video[]
            dispatch(setVideosList(videos))
            return;
        },
    });

    const dispatch: AppDispatch = useDispatch();
    const handleDelete = async (video: Video) => {
        toast.promise(
            apiService.deleteVideo(video._id!, token),
            {
                loading: '🗑️ Deleting Video...',
                success: <b>Video deleted!</b>,
                error: <b>Could not delete.</b>,
            }
        )
        pushEvent('DeleteVideo', { video })
        const filteredVideos = (videosList as Video[]).filter((v: Video) => v._id !== video._id)
        dispatch(setVideosList(filteredVideos))
    }
    useEffect(() => {
        pushEvent('UserPageView', { pageName: 'VideosListPage' });
        dispatch(setActiveRouteKey(ActiveRouteKey.UPLOADS));
        if (videosList && videosList?.length > 0) {
            dispatch(setVideosList(videosList));
        }
    }, [dispatch]);

    // Filter playbooks based on search query
    const filteredVideos = searchQuery === ''
        ? videosList
        : (videosList as Video[]).filter(v => v.name.toLowerCase().includes(searchQuery.toLowerCase()));

    // Handle search input change
    const onSearch = (value: string) => {
        pushEvent('SimpleSearchInVideosList', { searchQuery: value })
        setSearchQuery(value);
    };

    return (
        isLoading ? <Spin /> : <div className='videos-page'>
            <AutoComplete
                style={{ width: '95%', marginBottom: 16 }}
                onSearch={onSearch}
                placeholder="Search videos"
                size="large"
            />
            <div className="videos-list">
                {filteredVideos.map((item: Video, i: number) => (
                    <VideoItem item={item} key={i} handleDelete={handleDelete} />
                ))}
            </div>
        </div>
    )
}

const VideoItem = ({ item, key, handleDelete }: { item: Video, key: number, handleDelete: (item: any) => void }) => {
    const [isVisible, setIsVisible] = useState(false);
    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();
    return (
        <div key={key} className="videoItemContainerWrapper" onClick={() => {
            navigate(`/${ActiveRouteKey.PLAYBOOKS}/${item._id}`)
        }}>
            <div className='videoItemContainer'  >
                <h4 className='video-title'>{item?.name}</h4>
            </div>
            <Dropdown menu={{
                items: [
                    {
                        label: <p onClick={(e: any) => {
                            e.stopPropagation()
                            e.preventDefault()
                            handleDelete(item)
                            setIsVisible(false)
                        }}>Delete</p>,
                        key: '0',
                        icon: <DeleteOutlined />
                    },
                    {
                        label: <p onClick={(e: any) => {
                            e.stopPropagation()
                            e.preventDefault()
                            dispatch(setActivePlaybookId(item._id!))
                        }}>Set Active</p>,
                        key: '1',
                        icon: <CheckCircleOutlined />
                    }
                ]
            }}
                open={isVisible}
                trigger={['click']}>
                <MoreOutlined onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    setIsVisible(!isVisible)
                }} color='#fff' />
            </Dropdown>
        </div>
    )
}

export default VideosListPage
