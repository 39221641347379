import { FC, useState } from 'react';
import S3FileUpload from 'react-s3';
import { Upload, Button, message, Row } from 'antd';
import { DeleteOutlined, FireOutlined, VideoCameraAddOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
import { AppDispatch, RootState } from '@redux/store';
import { useDispatch, useSelector } from 'react-redux';
// import { setNumTries } from '@redux/features/userSlice';
import toast from 'react-hot-toast';
import { pushEvent } from '@utils/analytics';
import apiService from '@utils/api/api-service';
import { Contact } from '@models/contact.model';
import { RecordType } from '@models/index';
import { BottomSheetType, SearchType, setActiveQueryString, setActiveVideoUrl, setAudioNoteContent, setBottomSheetType, setHandleBottomSheetClose, setIsBottomSheetOpen, setSearchType } from '@redux/features/activeEntitiesSlice';
import { addVideoToList, setActiveVideo } from '@redux/features/video';
import VideosListPage from '../VideoList';

// S3 Configuration
const config = {
    bucketName: process.env.REACT_APP_AWS_BUCKET_NAME,
    dirName: 'video-recordings', /* optional */
    region: process.env.REACT_APP_AWS_REGION_NAME,
    accessKeyId: process.env.REACT_APP_AWS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET,
}

const UploadVideo: FC = () => {
    // const dispatch: AppDispatch = useDispatch();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { activeVideoUrl: videoUrl, activePlaybookId, geolocation } = useSelector((state: RootState) => state.activeEntities);
    const { email: userId, token, ownerId } = useSelector((state: RootState) => state.persisted.user.value)
    const activeVideo = useSelector((state: RootState) => state.video.activeVideo)


    const dispatch: AppDispatch = useDispatch();
    const uploadProps = {
        name: 'file',
        multiple: false,
        showUploadList: false,
        beforeUpload: async (file: File) => {
            // Generating a UUID and renaming the file before upload
            setIsLoading(true);
            const extension = file.name.split('.').pop() || '';
            const newName = `${uuidv4()}.${extension}`;
            const newFile = new File([file], newName, { type: file.type });

            toast.promise(
                new Promise(async (resolve, reject) => {
                    pushEvent('ClickUploadVideoRecording', {
                        // File size in MB
                        fileSize: file.size / (1024 ** 2)
                    })
                    const startTime = new Date().getTime();
                    try {
                        // Uploading the file to S3
                        const data = await S3FileUpload.uploadFile(newFile, config);
                        message.success(`File uploaded successfully. File is available at ${data.location}`);

                        dispatch(setActiveVideoUrl(data.location));

                        // dispatch(setContact(newContact))
                        // // dispatch(setIsBottomSheetOpen(true))
                        // dispatch(setBottomSheetType(BottomSheetType.CONTACT_ADD))
                        // const endTime = new Date().getTime();
                        // pushEvent('PhotoToContactCompleted', {
                        //     // File size in MB
                        //     fileSize: file.size / (1024 ** 2),
                        //     responseTime: (endTime - startTime) / 1000
                        // })
                        // dispatch(setNumTries(numTries + 1));
                        // setIsLoading(true);
                        // Now 'text' contains the extracted text from the .txt file

                        resolve("Success"); // you can modify this to return relevant data if needed
                        const createdVideoTranscriptObject = await apiService.createVideo({
                            videoUrl: data.location,
                            transcriptText: "",
                            userId,
                            createdAt: new Date().toISOString(),
                            name: file.name,
                        }, token)
                        dispatch(setActiveVideo(createdVideoTranscriptObject))
                        dispatch(addVideoToList(createdVideoTranscriptObject))
                    } catch (error) {
                        message.error('File upload failed or text extraction failed');
                        console.error(error);
                        reject(error);
                    } finally {
                        setIsLoading(false);
                    }

                })
                , {
                    loading: 'Uploading video....',
                    success: <b>Video uploaded!</b>,
                    error: <b>Could not upload video</b>,
                }
            )
            return false; // Return false to not automatically upload after select
        },
    };

    const transcribeAndProcess = async () => {
        if (!activePlaybookId) return toast('Please select a playbook first!')
        const toastId = toast.loading('Extracting text from video....');
        const transcriptResponse = await apiService.getVideoTranscriptFromText(videoUrl, token);
        const transcriptText = transcriptResponse.transcript;
        dispatch(setAudioNoteContent(transcriptText || ""));
        toast.success('Text extracted from video', { id: toastId });
        await apiService.updateVideo(activeVideo._id!, { transcriptText }, token)
        dispatch(setIsBottomSheetOpen(true));
        dispatch(setBottomSheetType(BottomSheetType.SEARCH_ADD));
        dispatch(setSearchType(SearchType.INTERNAL));
        dispatch(setHandleBottomSheetClose(async ({ contact }: any) => {
            // So one way or another, basically, we always have an "active contact" set in the contactSlice before we call the handleUpload function to add the note to that contact.
            try {
                const toastId2 = toast.loading('Populating CRM....');
                const syncedContact: Contact = await apiService.syncContactToCRM(contact, userId, token);
                await apiService.syncStructuredNoteToCRM({
                    content: transcriptText || "",
                    timestamp: new Date().toISOString(),
                    location: geolocation,
                    recordType: RecordType.NOTE,
                    contactId: contact.id!,
                    salesforceCrmContactId: syncedContact.salesforceCrmId,
                    hubspotCrmContactId: syncedContact.hubspotCrmId,
                    crmOwnerId: ownerId
                }, activePlaybookId, userId, token);
                toast.success('CRM populated', { id: toastId2 });
                const toastId3 = toast.loading('Sending followup email....');
                await apiService.sendEmailForVideo({ contact, transcript: transcriptText, token });
                toast.success('Email sent!', { id: toastId3 })
            } catch (error) {
                toast.error('Error doing it');
            } finally {
                dispatch(setActiveQueryString(''));
            }
        }))
    }

    return (
        <>
            <Upload {...uploadProps}>
                <Button disabled={isLoading} icon={<VideoCameraAddOutlined />}>Upload Video/Audio</Button>

            </Upload>
            <br /><br /><br /><br />
            {videoUrl?.length > 0 && <video width="320" height="240" controls>
                <source src={videoUrl} type="video/mp4" />
                Your browser does not support the video tag.
            </video>}
            <Row gutter={16}>
                <Button
                    style={{
                        border: 'none',
                        outline: 'none',
                    }}
                    disabled={videoUrl?.length === 0}
                    icon={<FireOutlined />}
                    onClick={transcribeAndProcess}
                >
                    Transcribe and Process Video
                </Button>
                <Button
                    style={{
                        border: 'none',
                        outline: 'none',
                    }}
                    type='primary'
                    disabled={videoUrl?.length === 0}
                    icon={<DeleteOutlined />}
                    onClick={() => dispatch(setActiveVideoUrl(''))}
                >
                    Clear Current Video
                </Button>
            </Row>
            <br></br>
            <VideosListPage />
        </>
    );
};

export default UploadVideo;
