import { Button, Form, Input } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { CompleteContentBlock, ContentBlock } from '@models/contentBlock.model';
import { CompleteTemplate, Template } from '@models/template.model';
import { addPlaybookToList, setActivePlaybook } from '@redux/features/playbookSlice';
import { RootState } from '@redux/store';
import apiService from '@utils/api/api-service';
import { setIsBottomSheetOpen } from '@redux/features/activeEntitiesSlice';
import toast from 'react-hot-toast';
import { useEffect } from 'react';
import { Option } from '@models/option.model';
import PlaybookContentBlock from '@components/molecules/PlaybookContentBlock';

export const toCamelCase = (str: string) => {
    // Split the string into words using a regular expression
    // The regex matches spaces and punctuation as word separators
    const words = str.match(/[a-z]+/gi);

    // Return an empty string if no words are found
    if (!words) return "";

    // Make the first word lowercase
    const firstWord = words[0].toLowerCase();

    // Capitalize the first letter of each remaining word and make the rest of the letters lowercase
    const capitalizedWords = words
        .slice(1)
        .map(word => word.charAt(0).toUpperCase() + word.substr(1).toLowerCase());

    // Combine the first word with the capitalized words
    return [firstWord, ...capitalizedWords].join('');
}

interface PlaybookFormValues {
    name: string;
    description: string;
    contentBlocks: {
        blockTitle: string;
        description: string;
        responseType: 'text' | 'number' | 'single-select' | 'multi-select';
        options?: { optionText: string }[]; // Array of optionTexts if the block is single or multi-select
    }[];
}
const AddPlaybookContainer: React.FC = () => {
    const [form] = Form.useForm();
    const { email: userId, token } = useSelector((state: RootState) => state.persisted.user.value);
    const dispatch = useDispatch();
    const activePlaybook = useSelector((state: RootState) => state.playbook.activePlaybook);

    useEffect(() => {
        form.setFieldsValue({
            name: activePlaybook.name,
            description: activePlaybook.description,
            contentBlocks: activePlaybook.contentBlocks // Ensure this is in the correct format
        });
    }, [form, activePlaybook])


    const onFinish = async (values: PlaybookFormValues) => {
        const formattedContentBlocks = values.contentBlocks.map((block: {
            blockTitle: string,
            description: string,
            options?: { optionText: string }[],
            responseType: 'text' | 'number' | 'single-select' | 'multi-select'
        }, i: number) => {
            const contentBlock: CompleteContentBlock = {
                templateId: '',
                blockTitle: block.blockTitle,
                description: block.description,
                // Block key basically just takes the block title string, and camelcases all the words in it. For eg: "Block Title" becomes "blockTitle". "Name of the company" becomes "nameOfTheCompany"
                blockKey: toCamelCase(block.blockTitle),
                // We pass in an array of Option objects with the content block itself and let the backend actually create the option and link it to the contentBlock as that is a much smoother flow as opposed to us creating the contentBlock first and then creating the options and linking them to the contentBlock
                options: block.options && block.options.length > 0 ? block.options.map((op, i) => {
                    return {
                        _id: '',
                        contentBlockId: '',
                        optionText: op.optionText,
                        order: i
                    }
                }) : [],
                order: i,
                responseType: block.responseType,
            };
            return contentBlock
        });

        try {
            const toastId = toast.loading('Creating playbook...');

            const template: Template = {
                ...values,
                userId,
                createdAt: new Date().toISOString(),
                updatedAt: new Date().toISOString()
            };
            if (!(activePlaybook._id)) {
                const createdTemplate = await apiService.createTemplate(template, token);
                // Once we have a createdTemplate, we can use the _id to create the contentBlocks
                // const contentBlocks = await Promise.all(formattedContentBlocks.map(async (block) => {
                //     const createdBlock = await apiService.createContentBlock({ ...block, templateId: createdTemplate._id }, token);
                //     return createdBlock;
                // }));
                const contentBlocks = await Promise.all(formattedContentBlocks.map(async (block) => {
                    const createdBlock = await apiService.createContentBlock({ ...block, templateId: createdTemplate._id }, token);

                    return createdBlock;
                }));

                const completeTemplate: CompleteTemplate = {
                    ...createdTemplate,
                    contentBlocks
                };
                dispatch(addPlaybookToList(completeTemplate));
                toast.success('Playbook created!', { id: toastId });
            } else {
                const updatedTemplate = await apiService.updateTemplate(activePlaybook._id, template, token);
                dispatch(setActivePlaybook(updatedTemplate));
                toast.success('Playbook updated!', { id: toastId });
            }

            // Reset form fields after submission
            form.resetFields();
        } catch (error: any) {
            toast.error(`Failed to create playbook due to ${error.message}. Please try again`);
        } finally {
            dispatch(setIsBottomSheetOpen(false));

        }
    };

    return (
        <Form
            form={form}
            name="add_playbook_form"
            onFinish={onFinish}
            layout="vertical"
            autoComplete="off"
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                gap: '12px',
            }}
        >
            <Form.Item
                name="name"
                label="Playbook Name"
                rules={[{ required: true, message: 'Please input the name of the playbook!' }]}
            >
                <Input
                    placeholder="Enter playbook name"
                // defaultValue={activePlaybook.name}
                />
            </Form.Item>
            <Form.Item
                name="description"
                label="Playbook Description"
                rules={[{ required: true, message: 'Please input the description of the playbook!' }]}
            >
                <Input.TextArea
                    // defaultValue={activePlaybook.description}
                    placeholder="Enter playbook description"
                />
            </Form.Item>
            <Form.List name="contentBlocks">
                {(fields, { add, remove }) => (
                    <>
                        <h4 style={{ margin: 0 }}>Fields to capture</h4>
                        {fields.map((field, index) => (
                            // <Space key={field.key} style={{ display: 'flex', marginBottom: 10 }} a ̑lign="baseline">
                            <PlaybookContentBlock
                                field={field}
                                form={form}
                                index={index}
                                remove={remove}
                            />
                            // </Space>
                        ))}
                        <br />
                        <Form.Item>
                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                Add Content Block
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>
            <Form.Item>
                <Button
                    style={{
                        width: '90%',
                        border: 'none',
                        color: 'white',
                        textAlign: 'center',
                        textDecoration: 'none',
                        display: 'inline-block',
                        fontSize: '16px',
                        cursor: 'pointer',
                        position: 'fixed',
                        bottom: 20,
                    }}
                    type="primary" htmlType="submit">
                    Submit Playbook
                </Button>
            </Form.Item>
        </Form>
    );
};

export default AddPlaybookContainer;
