import NotesList from '@components/molecules/NotesList';
import { CompleteContact, Contact } from '@models/contact.model';
import { setActiveQueryString, setAudioNoteContent, setIsBottomSheetOpen } from '@redux/features/activeEntitiesSlice';
import { addContactNote, initialContactState, setContact } from '@redux/features/contactSlice';
import { addContactToList } from '@redux/features/contactsListSlice';
import { AppDispatch, RootState } from '@redux/store';
import { pushEvent } from '@utils/analytics';
import apiService from '@utils/api/api-service';
import { Button, Form, Input } from 'antd';
import { TextArea } from 'antd-mobile';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import './AddContactContainer.css'
import { addRecentNoteToList } from '@redux/features/noteSlice';
import { uploadAudioForTranscription } from '@utils/transcribeAudio';
import { Note } from '@models/note.model';
import { v4 } from 'uuid';
import { RecordType } from '@models/index';
import AudioRecorder from '@components/molecules/AudioRecorder';
import AddContactReviewCategories from '@components/molecules/AddContactReviewCategories';
import { Option } from '@models/option.model';

// const MEDDPICC_TEMPLATE_ID = "65bed6b215e1e415796b88af"

// where clientPreferences corresponds to "contentBlocks" and clientChoices corresponds to object returned by GPT that contains the key-value pairs of the selected options
// TODO - Type safety
function extractOptionIdsFromChoices(clientPreferences: any, clientChoices: any) {
    // Initialize an array to hold the result
    const results: {
        contentBlockId: string;
        selectedOptionIds: string[];
    }[] = [];

    // Iterate through each contentBlock in clientPreferences
    clientPreferences.contentBlocks.forEach((block: any) => {
        const { _id: contentBlockId, blockKey, options } = block;
        const choice = clientChoices[blockKey];

        // Initialize the result object for this block
        let resultObj: {
            contentBlockId: string;
            selectedOptionIds: string[];
        } = { contentBlockId, selectedOptionIds: [] };

        if (Array.isArray(choice)) {
            // Multi-select scenario
            // Filter options that match the client's choices and map their IDs
            resultObj.selectedOptionIds = options
                .filter((option: any) => choice.includes(option.optionText))
                .map((option: any) => option._id);
        } else {
            // Single-select scenario
            // Find the option that matches the client's choice and map its ID
            const matchingOption: Partial<Option> = options.find((option: any) => option.optionText === choice);
            if (matchingOption) {
                resultObj.selectedOptionIds.push(matchingOption._id!);
            }
        }

        // Add the result object to the results array
        results.push(resultObj);
    });
    return results;
}



const AddContactContainer = () => {
    const contact = useSelector((state: RootState) => state.contact.value.activeContact);
    const [currentContact, setCurrentContact] = useState<CompleteContact>(contact!);
    const { email, token, ownerId, name, occupation, organization_name, biography } = useSelector((state: RootState) => state.persisted.user.value);
    const activePlaybookId = useSelector((state: RootState) => state.activeEntities.activePlaybookId);
    const handleChange = (changedFields: Partial<Contact>, fieldEdited: "name" | "email" | "biography" | "phone" | "organization_name" | "occupation" | "location") => {
        setCurrentContact({ ...currentContact, ...changedFields });
        pushEvent('UpdateContactProperties', { fieldEdited });
    }
    const { audioNoteContent, geolocation } = useSelector((state: RootState) => state.activeEntities);
    const dispatch: AppDispatch = useDispatch();
    const [form] = Form.useForm();
    const [reviewInfoFilter, setReviewInfoFilter] = useState<"info" | "notes">("info");

    const handleAudioFile = async (audioFile: any) => {
        const toastId = toast.loading('Transcribing...');
        const data: { text: string } = await uploadAudioForTranscription(audioFile)
        toast.success('Transcribed!', { id: toastId });
        const note: Note = {
            id: v4(),
            content: data.text,
            timestamp: new Date().toISOString(),
            location: geolocation,
            recordType: RecordType.NOTE,
            contactId: ""
        }
        dispatch(addContactNote(note))
        setCurrentContact({ ...currentContact, notes: [...currentContact.notes!, note] });
        pushEvent('AddPersonAudioNoteRightBeforeSubmitting', (note as Note))
    }

    useEffect(() => {
        if (audioNoteContent) {
            setCurrentContact({
                ...currentContact,
                notes: [
                    ...currentContact.notes!,
                    {
                        content: audioNoteContent,
                        timestamp: new Date().toISOString(),
                        id: v4(),
                        location: geolocation,
                        recordType: RecordType.NOTE,
                        contactId: ""
                    }]
            });
        }
    }, [audioNoteContent])

    const onFinish = async () => {
        const toastId = toast.loading('Saving contact...');
        const startTime = new Date().getTime();
        const { notes, actions, ...newContact } = currentContact;
        let recentlyCreatedContact = newContact;
        // const latestNote = notes![notes!.length! - 1

        notes?.forEach(note => {
            const noteSource = note?.noteSource;
            const isNoteSourceLinkedin = noteSource?.includes('linkedin');
            if (isNoteSourceLinkedin) newContact.linkedinUrl = noteSource;
            const isNoteSourceTwitter = noteSource?.includes('twitter') || noteSource?.includes('x.com');
            if (isNoteSourceTwitter) newContact.twitterUrl = noteSource;
            const isNoteSourceInstagram = noteSource?.includes('instagram');
            if (isNoteSourceInstagram) newContact.instagramUrl = noteSource;
        });

        if (ownerId) newContact.crmOwnerId = ownerId;
        pushEvent('SubmitCompleteContact', newContact)
        try {
            // Map over all the notes and call the apiService.createNote method over them to create notes in the database
            const createdContact = await apiService.createContact(newContact, email, token);
            recentlyCreatedContact = createdContact;
            // const notesWithContactId = notes?.map(note => ({ ...note, contactId: createdContact.id! }));
            // Iterate over all the `notes` using forEach and have a promise to create each note properly and wait till all notes are created

            notes?.forEach(async note => {
                const createdNote = await apiService.createNote({
                    ...note,
                    contactId: createdContact.id!,
                    crmOwnerId: ownerId
                }, email, token);
                dispatch(addRecentNoteToList(createdNote));
            });



            // // TODO: Patchfix to update salesforce contact's description with content of latest note
            // await apiService.updateSalesforceContactById(createdContact.id!, audioNoteContent || note.content, token)


            // await notesWithContactId?.map(note => apiService.createNote(note, email, token));
            const endTime = new Date().getTime();
            pushEvent('AddContactCompleted', { responseTime: (endTime - startTime) / 1000 })
            toast.success('Contact Stored!', { id: toastId })

            dispatch(setAudioNoteContent(""))
            dispatch(setActiveQueryString(""))
        } catch (error) {
            // Handle error
            console.error(error);
        } finally {
            dispatch(setIsBottomSheetOpen(false));
            dispatch(setContact(initialContactState.value.activeContact));
            form.resetFields();
            dispatch(addContactToList(recentlyCreatedContact));
            const toastId = toast.loading('Syncing to CRM...');
            const syncedContact: Contact = await apiService.syncContactToCRM(recentlyCreatedContact, email, token);
            // notes?.forEach(async note => {
            //     await apiService.syncNoteToCRM({
            //         ...note,
            //         contactId: recentlyCreatedContact.id!,
            //         salesforceCrmContactId: syncedContact.salesforceCrmId,
            //         hubspotCrmContactId: syncedContact.hubspotCrmId
            //     }, token);
            // })

            const combinedNoteContent = notes?.map(note => note.content).join('\n \n') || "";
            await apiService.syncStructuredNoteToCRM({
                content: combinedNoteContent || "",
                timestamp: new Date().toISOString(),
                location: geolocation,
                recordType: RecordType.NOTE,
                contactId: recentlyCreatedContact.id!,
                salesforceCrmContactId: syncedContact.salesforceCrmId,
                hubspotCrmContactId: syncedContact.hubspotCrmId,
                crmOwnerId: ownerId
            }, activePlaybookId, email, token);
            toast.success('Synced to CRM!', { id: toastId })
            const toastId2 = toast.loading('Sending email...');
            await apiService.sendEmail({
                contact: recentlyCreatedContact,
                noteString: combinedNoteContent,
                token,
                senderIntro: `${name} is occupied as ${occupation} at ${organization_name}. ${biography} \n \n`

            });
            toast.success('Email sent!', { id: toastId2 })
        }
    };

    return (
        <div style={{ height: '100%', marginBottom: '10rem', overflow: 'auto' }}>
            <h2>Saving new contact....</h2>
            <Form form={form} onFinish={onFinish}>
                <Form.Item label="Given Name">
                    <Input onChange={(e) => handleChange(
                        { name: e.target.value as string }, "name")
                    }
                        defaultValue={currentContact.name}
                    />
                </Form.Item>
                <Form.Item label="Bio">
                    <TextArea rows={6} onChange={(value) =>
                        handleChange(
                            { biography: value as string }, "biography")
                    }
                        defaultValue={currentContact.biography}
                    />
                </Form.Item>
                <Form.Item label="Phone Number">
                    <Input
                        type='tel'
                        onChange={(e) =>
                            handleChange(
                                { phone: e.target.value }, "phone")
                        }
                        defaultValue={currentContact.phone}
                    />
                    {reviewInfoFilter === 'notes' && <NotesList notes={currentContact?.notes || []} />}
                </Form.Item>
                <Form.Item label="Occupation">
                    <Input onChange={(e) => handleChange(
                        { occupation: e.target.value as string }, "occupation")}
                        defaultValue={currentContact.occupation}
                    />
                </Form.Item>
                <Form.Item label="Company">
                    <Input onChange={(e) => handleChange(
                        { organization_name: e.target.value as string }, "organization_name")}
                        defaultValue={currentContact.organization_name}
                    />
                </Form.Item>
                <Form.Item label="Email">
                    <Input type="email" onChange={(e) =>
                        handleChange(
                            { email: e.target.value as string }, "email")
                    }
                        defaultValue={currentContact.email}
                    />
                </Form.Item>
                <Form.Item label="Location">
                    <Input type="location" onChange={(e) =>
                        handleChange(
                            { location: e.target.value as string }, "location")
                    }
                        defaultValue={currentContact.location}
                    />
                </Form.Item>
            </Form>
            <div className="bottomAddBar">
                <div style={{
                    width: '100%',
                    height: '10rem',
                    background: 'linear-gradient(180deg, transparent 0%, #fff 60%)',
                }} />
                <div className="buttonWrapper">
                    <div
                        style={{
                            width: "100%",
                            padding: "1rem 0",
                            display: "flex",
                            justifyContent: "center"
                        }}
                    >
                        <AddContactReviewCategories activeFilter={reviewInfoFilter} setActiveFilter={setReviewInfoFilter} />
                        <AudioRecorder handleAudioFile={handleAudioFile} />
                    </div>
                    <Button
                        type="primary"
                        htmlType="submit"
                        onClick={
                            () => {
                                form.submit();
                            }
                        }
                    >
                        Submit
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default AddContactContainer
