import { RecordType } from "@models/index";
import { CompleteLinkedinContact } from "@models/linkedinContact.model";
import { CompleteUser, User, UserStatus } from "@models/user.model";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const initialHardCodedUser: { value: CompleteUser } = {
  value: {
    id: "",
    name: "Ankit Sanghvi",
    email: "theankitsanghvi@gmail.com",
    token: "",
    subscriptionExpiryDate: "",
    status: UserStatus.UNAUTHENTICATED,
    recordType: RecordType.USER,
    calendarId: ""
  }
}

export const initialUserState: { value: CompleteUser } = {
  value: {
    token: "",
    // numTries: 0,
    subscriptionExpiryDate: "",
    email: "",
    name: "",
    photoURL: "",
    status: UserStatus.UNAUTHENTICATED,
    calendarId: ""
  }
}

export const userSlice = createSlice({
  name: "user",
  // initialState: initialHardCodedUser,
  initialState: initialUserState,
  reducers: {
    setUser(state: { value: CompleteUser }, action: PayloadAction<User>) {
      state.value = action.payload
    },
    setUserToken(state: { value: CompleteUser }, action: PayloadAction<string>) {
      state.value.token = action.payload
    },
    // setNumTries(state: { value: CompleteUser }, action: PayloadAction<number>) {
    //   state.value.numTries = action.payload
    // },
    setSubscriptionExpiryDate(state: { value: CompleteUser }, action: PayloadAction<string>) {
      state.value.subscriptionExpiryDate = action.payload
    },
    setUserEmail(state: { value: CompleteUser }, action: PayloadAction<string>) {
      state.value.email = action.payload
    },
    setUserPhotoURL(state: { value: CompleteUser }, action: PayloadAction<string>) {
      state.value.photoURL = action.payload
    },
    setUserName(state: { value: CompleteUser }, action: PayloadAction<string>) {
      state.value.name = action.payload
    },
    setUserStatus(state: { value: CompleteUser }, action: PayloadAction<UserStatus>) {
      state.value.status = action.payload
    },
    setUserLinkedinContact(state: { value: CompleteUser }, action: PayloadAction<CompleteLinkedinContact>) {
      state.value.linkedinContact = action.payload
      state.value.linkedinContactId = action.payload.id
    },
    setUserCalendarId(state: { value: CompleteUser }, action: PayloadAction<string>) {
      state.value.calendarId = action.payload
    }
  },
});

export const {
  setUserToken,
  // setNumTries,
  setSubscriptionExpiryDate,
  setUserEmail,
  setUserPhotoURL,
  setUserName,
  setUserStatus,
  setUser,
  setUserCalendarId,
  setUserLinkedinContact
} = userSlice.actions;

export default userSlice.reducer;
