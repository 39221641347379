import { Note } from "@models/note.model";
import { callApi } from "@utils/api/api-service";

export const createNote = async (note: Partial<Note>, userId: string, accessToken: string) => {
    return await callApi({
        method: "POST",
        url: `/notes/${userId}`,
        accessToken,
        requestBody: note,
        serializerFunc: (r: { data: Note }) => r.data
    });
};

export const getNote = async (id: string, userId: string, accessToken: string) => {
    return await callApi({
        method: "GET",
        url: `/notes/${userId}/${id}`,
        accessToken,
        serializerFunc: (r: { data: Note }) => r.data
    });
};

export const getRecentNotes = async (userId: string, accessToken: string) => {
    return await callApi({
        method: "POST",
        url: `/notes`,
        requestBody: { userId },
        accessToken,
        serializerFunc: (r: { data: Note[] }) => r.data
    });
}

export const updateNote = async (id: string, note: Partial<Note>, userId: string, accessToken: string) => {
    return await callApi({
        method: "PUT",
        url: `/notes/${userId}/${id}`,
        accessToken,
        requestBody: note,
        serializerFunc: (r: { data: Note }) => r.data
    });
};


export const deleteCachedNote = async (id: string, userId: string, accessToken: string) => {
    return await callApi({
        method: "DELETE",
        url: `/notes/deleteCachedNote`,
        accessToken,
        requestBody: { noteId: id, userId },
        serializerFunc: () => { }
    });
}

export const deleteNote = async (id: string, userId: string, accessToken: string) => {
    return await callApi({
        method: "DELETE",
        url: `/notes/${userId}/${id}`,
        accessToken,
        serializerFunc: () => { }
    });
};

export const getMultipleNotes = async (noteIds: string[], userId: string, accessToken: string) => {
    return await callApi({
        method: "POST",
        url: `/notes/${userId}/getNotes`,
        accessToken,
        requestBody: { noteIds },
        serializerFunc: (r: { data: Note[] }) => r.data
    });
};

export const syncNoteToCRM = async (note: Note, accessToken: string) => {
    return await callApi({
        method: "PUT",
        url: `/notes/syncNoteToCRM`,
        accessToken,
        requestBody: { note },
        serializerFunc: (r: { data: Note }) => (r.data)
    })
}

export const syncStructuredNoteToCRM = async (note: Note, templateId: string, userId: string, accessToken: string) => {
    return await callApi({
        method: "PUT",
        url: `/notes/syncStructuredNoteToCRM`,
        accessToken,
        requestBody: { note, templateId, userId },
        serializerFunc: (r: { data: Note }) => (r.data)
    })
}