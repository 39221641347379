import { createBrowserRouter } from "react-router-dom";
import App from '../App';
import ErrorPage from "@components/pages/ErrorPage";
import ContactsListPage from "@components/pages/ContactsListPage";
import AccountPage from "@components/pages/AccountPage";
import ContactPage from "@components/pages/ContactPage";
import ActionPage from "@components/pages/AgendaPage";
import { ActiveRouteKey } from "@redux/features/activeEntitiesSlice";
import HomePage from "@components/pages/HomePage";
import GroupsListPage from "@components/pages/GroupsListPage";
import GroupPage from "@components/pages/GroupPage";
import SearchInNetworkPage from "@components/pages/SearchInNetworkPage";
import ChatInNetworkPage from "@components/pages/ChatInNetworkPage";
import * as Sentry from "@sentry/react";
import PlaybooksListPage from "@components/pages/PlaybooksListPage";
import PlaybookPage from "@components/pages/PlaybookPage";
import UploadVideo from "@components/molecules/UploadVideo";
import PlaybooksDashboard from "@components/organisms/PlaybooksDashboard";

export const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        errorElement: <Sentry.ErrorBoundary>
            {/* Checkout https://docs.sentry.io/platforms/javascript/guides/react/features/error-boundary/ for more info on handling this */}
            <ErrorPage />
        </Sentry.ErrorBoundary>,
        children: [
            // {
            //     path: "mic",
            //     element: <AudioRecordPage />,
            // },
            {
                path: "",
                element: <Sentry.ErrorBoundary
                    beforeCapture={(scope) => {
                        scope.setTag("route", ActiveRouteKey.HOME);
                    }}
                >
                    <HomePage />
                </Sentry.ErrorBoundary>,
            },
            {
                path: ActiveRouteKey.HOME,
                element: <Sentry.ErrorBoundary
                    beforeCapture={(scope) => {
                        scope.setTag("route", ActiveRouteKey.HOME);
                    }}
                >
                    <HomePage />
                </Sentry.ErrorBoundary>,
            },
            {
                path: `${ActiveRouteKey.CONTACTS}/:id`,
                element: <Sentry.ErrorBoundary
                    beforeCapture={(scope) => {
                        scope.setTag("route", `${ActiveRouteKey.CONTACTS}/:id`);
                    }}
                >
                    <ContactPage />
                </Sentry.ErrorBoundary>,
            },
            {
                path: `${ActiveRouteKey.GROUPS}/:id`,
                element: <Sentry.ErrorBoundary
                    beforeCapture={(scope) => {
                        scope.setTag("route", `${ActiveRouteKey.GROUPS}/:id`);
                    }}
                >
                    <GroupPage />
                </Sentry.ErrorBoundary>,
            },
            {
                path: ActiveRouteKey.CONTACTS,
                element: <Sentry.ErrorBoundary
                    beforeCapture={(scope) => {
                        scope.setTag("route", ActiveRouteKey.CONTACTS);
                    }}
                >
                    <ContactsListPage />
                </Sentry.ErrorBoundary>,
            },
            {
                path: ActiveRouteKey.ACCOUNT,
                element: <Sentry.ErrorBoundary
                    beforeCapture={(scope) => {
                        scope.setTag("route", ActiveRouteKey.ACCOUNT);
                    }}
                >
                    <AccountPage />
                </Sentry.ErrorBoundary>,
            },
            {
                path: ActiveRouteKey.GROUPS,
                element: <Sentry.ErrorBoundary
                    beforeCapture={(scope) => {
                        scope.setTag("route", ActiveRouteKey.GROUPS);
                    }}
                >
                    <GroupsListPage />
                </Sentry.ErrorBoundary>,
            },
            {
                path: ActiveRouteKey.SEARCH,
                element: <Sentry.ErrorBoundary
                    beforeCapture={(scope) => {
                        scope.setTag("route", ActiveRouteKey.SEARCH);
                    }}
                >
                    <SearchInNetworkPage />
                </Sentry.ErrorBoundary>,
            },
            {
                path: ActiveRouteKey.SEARCH2,
                element: <Sentry.ErrorBoundary
                    beforeCapture={(scope) => {
                        scope.setTag("route", ActiveRouteKey.SEARCH2);
                    }}
                >
                    <ChatInNetworkPage />
                </Sentry.ErrorBoundary>,
            },
            {
                path: ActiveRouteKey.ACTIONS,
                element: <Sentry.ErrorBoundary
                    beforeCapture={(scope) => {
                        scope.setTag("route", ActiveRouteKey.ACTIONS);
                    }}
                >
                    <ActionPage />
                </Sentry.ErrorBoundary>,
            },
            {
                path: ActiveRouteKey.PLAYBOOKS,
                element: <Sentry.ErrorBoundary
                    beforeCapture={(scope) => {
                        scope.setTag("route", ActiveRouteKey.PLAYBOOKS);
                    }}
                >
                    <PlaybooksListPage />
                </Sentry.ErrorBoundary>,
            },
            {
                path: `${ActiveRouteKey.PLAYBOOKS}/:id`,
                element: <Sentry.ErrorBoundary
                    beforeCapture={(scope) => {
                        scope.setTag("route", `${ActiveRouteKey.PLAYBOOKS}/:id`);
                    }}
                >
                    <PlaybookPage />
                </Sentry.ErrorBoundary>,
            },
            {
                path: `${ActiveRouteKey.UPLOADS}`,
                element: <Sentry.ErrorBoundary
                    beforeCapture={(scope) => {
                        scope.setTag("route", `${ActiveRouteKey.UPLOADS}`);
                    }}
                >
                    <UploadVideo />
                </Sentry.ErrorBoundary>,
            },
        ],
    },
]);