import { ActiveModalType } from '@redux/features/activeEntitiesSlice'
import { RootState } from '@redux/store'
import { useSelector } from 'react-redux'
import EditContactModalV2 from '../../organisms/EditContactModalV2'
import EditNoteModal from '../../organisms/EditNoteModal'
import OnlineSearchResultsModal from '../../organisms/SearchResultsModal'
import EditActionModal from '../../organisms/EditActionModal'
import EditSuggestedActionsModal from '../../organisms/EditSuggestedActionsModal'

const RootModal = () => {
    const { isModalOpen, modalType } = useSelector((state: RootState) => state.activeEntities)

    // Switch case to render the correct modal based on modal type
    const getModal = () => {
        switch (modalType) {
            case ActiveModalType.CONTACT_MODAL:
                return <EditContactModalV2 />
            case ActiveModalType.NOTE_MODAL:
                return <EditNoteModal />
            case ActiveModalType.ACTION_MODAL:
                return <EditActionModal />
            case ActiveModalType.ONLINE_SEARCH_MODAL:
                return <OnlineSearchResultsModal />
            case ActiveModalType.EDIT_SUGGESTED_ACTIONS:
                return <EditSuggestedActionsModal />
            default:
                return null
        }
    }
    return (
        <>
            {isModalOpen && getModal()}
        </>
    )
}

export default RootModal
