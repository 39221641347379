import React, { useEffect } from 'react';
import { Card, Avatar, Typography, Space, Button } from 'antd';
import { DownloadOutlined, UserOutlined } from '@ant-design/icons';
// import { useAuth } from '@contexts/AuthContext';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@redux/store';
import { pushEvent } from '@utils/analytics';
import { ActiveRouteKey, setActiveRouteKey } from '@redux/features/activeEntitiesSlice';
import UserLinkedinDetails from '@components/molecules/UserLinkedinDetails';
import { initialUserState, setUser } from '@redux/features/userSlice';
import { RevertConnect } from '@revertdotdev/revert-react';


const AccountPage: React.FC = () => {
    const user = useSelector((state: RootState) => state.persisted.user.value)
    const dispatch: AppDispatch = useDispatch();
    // The function to trigger the installation prompt
    useEffect(() => {
        pushEvent('UserPageView', { pageName: 'AccountPage' })
        dispatch(setActiveRouteKey(ActiveRouteKey.ACCOUNT))
    })
    const promptInstall = () => {
        if ((window as any).deferredPrompt) {
            (window as any).deferredPrompt.prompt();
            (window as any).deferredPrompt.userChoice.then((choiceResult: any) => {
                if (choiceResult.outcome === 'accepted') {
                    // console.log('User accepted the install prompt');
                } else {
                    // console.log('User dismissed the install prompt');
                }
                (window as any).deferredPrompt = null;
            });
        }
    };
    // const { logout } = useAuth()
    // const pendingTries = 5 - user.numTries as number > 0 ? 5 - user.numTries as number : 0
    return (
        <>
            <div style={{ width: '100%', alignItems: 'center', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                <a style={{ color: '#00e5ff' }} href="https://www.youtube.com/watch?v=P7W2sonLBko">Checkout Tutorial</a>
                <a style={{ color: '#00e5ff' }} href="https://asanghvi.notion.site/Privacy-Policy-3e4286d4ccd04e92a2c18ff183b1767e">Privacy Policy</a>
                <a style={{ color: '#00e5ff' }} href="https://asanghvi.notion.site/Terms-of-Service-15bc8d017ae24f12a2045b1b05642853">Terms of Service</a>
                <br />
            </div>
            <RevertConnect
                config={{
                    revertToken: 'pk_live_8b8c9d28-ab12-4d15-a3bb-12274c5804cd',
                    tenantId: '123',
                }}
            />
            <Card style={{ maxWidth: 400 }}>
                <div style={{ textAlign: 'center' }}>
                    <Avatar
                        size={120}
                        icon={<UserOutlined />}
                        src={user.photoURL}
                        style={{ margin: 'auto' }}
                    />
                    <Typography.Title level={3} style={{ marginTop: 20 }}>
                        {user.name || 'User'}
                    </Typography.Title>
                </div>

                <Space direction="vertical" style={{ width: '100%' }}>
                    <Typography.Text>
                        <strong>Email:</strong> {user.email}
                    </Typography.Text>
                    <UserLinkedinDetails linkedinContactId={user?.linkedinContactId || ""} />
                    {/* <Typography.Text>
                        <strong>Number of tries left:</strong> {pendingTries}
                    </Typography.Text>
                    <Typography.Text>
                        <strong>Subscription Expiry Date:</strong> {user.subscriptionExpiryDate}
                    </Typography.Text> */}
                    {/* <Button onClick={logout}>Logout</Button> */}
                </Space>
            </Card>
            <br /><br />
            <div style={{ width: '100%', alignItems: 'center', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                <Button
                    onClick={() => {
                        dispatch(setUser(initialUserState.value))
                        window.location.reload()
                    }}
                >Logout</Button>
                <p style={{ color: '#ccc' }}>Recontact v9</p>
            </div>

            <br />
            <Button
                icon={<DownloadOutlined />}
                onClick={promptInstall}
                style={{
                    fontFamily: 'Manrope',
                    padding: '0.2rem 0.5rem 0.2rem 0.5rem',
                    backgroundColor: '#1a1a1a',
                    color: '#fff',
                }}>
                Install App
            </Button> <br /><br /><br /><br /><br /><br /><br /><br />
        </>
    );
};

export default AccountPage;

// Example usage:
// <UserProfile user={yourUserObject} />
