import './ChatInNetworkPage.css'
import { pushEvent } from '@utils/analytics';
import ChatBar from '@components/molecules/ChatBar';
import { RecordType } from '@models/index';
import React, { useEffect, useRef, useState } from 'react'; // ensure to create this SCSS file with styles
import { AppDispatch, RootState } from '@redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { ActiveRouteKey, setActiveChatCycleId, setActiveRouteKey } from '@redux/features/activeEntitiesSlice';
import AIChatResponse from '@components/atoms/AIChatResponse';
import { ChatCycle, ChatRequest, startChatThreadWithRequest } from '@redux/features/chatSlice';
import { v4 } from 'uuid';
import AIChatContactsList from '@components/molecules/AIChatContactsList';
import { FloatButton } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import useEffectOnce from '@hooks/useEffectOnce';
import toast from 'react-hot-toast';

const ChatInNetworkPage: React.FC = () => {
    const [requestQuery, setRequestQuery] = useState<string>("");
    const { name } = useSelector((state: RootState) => state.persisted.user.value);
    const dispatch: AppDispatch = useDispatch();
    const chatCycles = useSelector((state: RootState) => state.chat.value.chatCycles);
    const endOfMessagesRef = useRef<HTMLDivElement>(null);
    // const navigate = useNavigate();

    // useEffectOnce(() => {
    //     toast("Switched to 'AI Chat' mode")
    // })

    useEffect(() => {
        pushEvent('UserPageView', { pageName: 'ChatNetworkPage' })
        dispatch(setActiveRouteKey(ActiveRouteKey.SEARCH2))
    })

    useEffect(() => {
        if (endOfMessagesRef.current) {
            endOfMessagesRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [chatCycles.length]);

    const handleSearch = async (input: string) => {
        setRequestQuery(input);
        const chatCycleId = v4();
        const chatRequest: ChatRequest = {
            text: input,
            timestamp: new Date().toISOString(),
            sender: 'user'
        }
        dispatch(startChatThreadWithRequest({ chatRequest, chatCycleId }));
        dispatch(setActiveChatCycleId(chatCycleId));
    }
    return (
        <>
            <div className='chatInNetContainer'>
                {chatCycles?.length > 0 ? chatCycles.map((chatCycle: ChatCycle, index: number) => {
                    return (
                        <div className='messagesContainer' key={index}>
                            <p className='messageBubble fromMe'>
                                {name}: {chatCycle?.request?.text}
                            </p>
                            <AIChatResponse chatCycleId={chatCycle.id} requestQuery={requestQuery} />
                            <AIChatContactsList chatCycleId={chatCycle.id} requestQuery={requestQuery} />
                        </div>
                    )
                })
                    :
                    <p style={{ color: 'white', fontSize: '0.9rem' }}>
                        Need to find the right contacts? Just ask with voice or text! Say 'Find me contacts in the finance industry located in New York,' 'Who can I speak to about jobs in marketing,' or 'I'm building a team of software engineers, who could be a good fit?' Our AI search digs deep into your connections to find exactly who you need!
                    </p>

                }
                <div ref={endOfMessagesRef} />
                <br /><br /><br /><br /><br /><br /><br />
            </div>
            {/* <FloatButton
                icon={<SearchOutlined />}
                type="default" style={{ right: 24, bottom: 180 }}
                onClick={() => navigate(`/${ActiveRouteKey.SEARCH}`)}
            /> */}
            <ChatBar
                handleUpload={handleSearch}
                action={"ChatContactInNetwork"}
                actionEntityType={RecordType.CONTACT}
            />
        </>
    );
};

export default ChatInNetworkPage;
