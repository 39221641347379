import './HomePage.css'
import React, { useEffect } from 'react';
import { FloatButton } from 'antd';
import { CheckCircleOutlined, FormOutlined, HighlightOutlined, UploadOutlined, UserAddOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@redux/store';
import { setActiveRouteKey, ActiveRouteKey, setActiveQueryString, setIsBottomSheetOpen, setBottomSheetType, BottomSheetType, setSearchType, SearchType } from '@redux/features/activeEntitiesSlice';
import { pushEvent } from '@utils/analytics';
import { initialContactState, setContact } from '@redux/features/contactSlice';
import MasterAIChatBar from '@components/molecules/MasterAIChatBar';
import { Contact } from '@models/contact.model';
import { RecordType } from '@models/index';
import RecentNotesList from '@components/molecules/RecentNotesList';
import RecentActionsList from '@components/molecules/RecentActionsList';
import { useNavigate } from 'react-router-dom';
import { AddOutline } from 'antd-mobile-icons';

const HomePage: React.FC = () => {
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        pushEvent('UserPageView', { pageName: 'HomePage' });
        dispatch(setActiveRouteKey(ActiveRouteKey.HOME));
        dispatch(setContact(initialContactState.value.activeContact));
        dispatch(setActiveQueryString(''))
    }, [dispatch]);

    const activeRouteKey = useSelector((state: any) => state.activeEntities.activeRouteKey)
    const [open, setOpen] = React.useState(false);


    const handleContactAddClick = () => {
        // Users use search functionality in multiple pages and when user switches pages, ideally, they don't want to carry over the old search string, so this makes sense
        dispatch(setActiveQueryString(''))
        dispatch(setIsBottomSheetOpen(true))
        dispatch(setSearchType(SearchType.EXTERNAL))
        dispatch(setContact(initialContactState.value.activeContact!))
        dispatch(setBottomSheetType(BottomSheetType.SEARCH_ADD))
    }

    const handleNoteAddClick = () => {
        // Users use search functionality in multiple pages and when user switches pages, ideally, they don't want to carry over the old search string, so this makes sense
        pushEvent('StartRichTextNoteAdd')
        dispatch(setActiveQueryString(''))
        dispatch(setIsBottomSheetOpen(true))
        dispatch(setContact(initialContactState.value.activeContact!))
        dispatch(setBottomSheetType(BottomSheetType.NOTE_ADD))
    }
    const [homepageFilter, setHomepageFilter] = React.useState<RecordType>(RecordType.NOTE);

    return (
        <div className="addPage">
            <div>
                {homepageFilter === RecordType.NOTE
                    ? <RecentNotesList />
                    : <RecentActionsList />
                }
                <br /><br /><br /><br /><br /><br /><br /><br />
            </div>

            <MasterAIChatBar presetContact={initialContactState.value as unknown as Contact} />
            <FloatButton.Group
                open={open}
                onClick={() => setOpen(!open)}
                trigger="click"
                type="primary"
                style={{ right: 20, bottom: 140 }}
                icon={<AddOutline />}
            >
                <FloatButton
                    type="default"
                    // style={{ bottom: 140, right: 20 }}
                    icon={<UserAddOutlined />}
                    onClick={handleContactAddClick}
                />
                <FloatButton
                    icon={<HighlightOutlined />}
                    type="default"
                    // style={{ right: 20, bottom: 190 }}
                    onClick={() => handleNoteAddClick()}
                />
                <FloatButton
                    icon={<UploadOutlined />}
                    type="default"
                    // style={{ right: 20, bottom: 290 }}
                    onClick={() => navigate(`/${ActiveRouteKey.UPLOADS}`)}
                />
                <FloatButton
                    icon={homepageFilter === RecordType.NOTE
                        ? <CheckCircleOutlined />
                        : <FormOutlined />
                    }
                    type="default"
                    // style={{ right: 20, bottom: 240 }}
                    onClick={() => setHomepageFilter(homepageFilter === RecordType.NOTE ? RecordType.ACTION : RecordType.NOTE)}
                />
            </FloatButton.Group>
        </div>
    );
};

export default HomePage;
